<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputOptionSmall
        :letter="'a'"
        :option="'Single'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-single.png'"
        :selected="
          personalDetails.marital_status === 'Single' &&
          !personalDetails.marital_status_single_divorced &&
          !personalDetails.marital_status_single_widowed
        "
        @selected="save('Single')"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'b'"
        :option="'Married'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-married.png'"
        :selected="personalDetails.marital_status === 'Married'"
        @selected="save('Married')"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'c'"
        :option="'Engaged'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-engaged2.png'"
        :selected="personalDetails.marital_status === 'Engaged'"
        @selected="save('Engaged')"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'d'"
        :option="'Co-habiting'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-cohabit.png'"
        :selected="personalDetails.marital_status === 'Co-habiting'"
        @selected="save('Co-habiting')"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'e'"
        :option="'Civil Partner'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-civilpartners.png'"
        :selected="personalDetails.marital_status === 'Civil Partner'"
        @selected="save('Civil Partner')"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'f'"
        :option="'Separated'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-separated.png'"
        :selected="personalDetails.marital_status === 'Separated'"
        @selected="save('Separated')"
      ></InputOptionSmall>

      <InputOptionSmall
        :letter="'g'"
        :option="'Widowed'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-widowed.png'"
        :selected="
          personalDetails.marital_status === 'Single' &&
          personalDetails.marital_status_single_widowed
        "
        @selected="save('Single', true, false)"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'h'"
        :option="'Divorced'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-divorced.png'"
        :selected="
          personalDetails.marital_status === 'Single' &&
          personalDetails.marital_status_single_divorced
        "
        @selected="save('Single', false, true)"
      ></InputOptionSmall>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionSmall from '@/common/ui/InputOptionSmall'

export default {
  name: 'ProfileMaritalStatus',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionSmall
  },
  mounted() {
    this.$emit('progress', '72%')
  },
  computed: {
    background() {
      if (this.start) return 'bg-personal-details-cookie'
      return 'bg-personal-details'
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    partner() {
      return this.$store.getters.partner
    },
    partner_ex() {
      return this.$store.getters.partner_ex
    },
    number() {
      if (
        this.$store.getters.checkoutBasket &&
        this.$store.getters.checkoutBasket.coupon &&
        this.$store.getters.checkoutBasket.coupon.charity
      )
        return '10'
      return '8'
    },
    start() {
      return this.$router.history.current.name === 'WriteMyWillMaritalStatus'
    },
    forwardTo() {
      if (
        this.personalDetails &&
        this.personalDetails.marital_status === 'Single' &&
        this.start
      ) {
        return '/write_my_will/username'
      } else if (
        this.personalDetails &&
        this.personalDetails.marital_status === 'Single'
      ) {
        return '/profile/check_and_confirm'
      } else if (this.start) return '/write_my_will/partner_name'
      return '/profile/partner_name'
    },
    backTo() {
      // if (this.start) return '/write_my_will/address'
      // return '/profile/address'
      if (this.start) return '/write_my_will/residential_status'
      return '/profile/residential_status'
    },
    heading() {
      return 'What is your marital status?'
    },
    subHeading() {
      return "Please select the status that most accurately reflects your current situation.  If you have been married previously and are now divorced please select 'single'."
    },

    isValidated() {
      return this.personalDetails.marital_status !== null;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.personalDetails.marital_status === 'Single') this.resetPartner()
    next()
  },
  methods: {
    save(value, widowed = false, divorced = false) {
      if (this.personalDetails) {
        this.$store.commit('personalDetails', {
          marital_status: value,
          marital_status_single_widowed: widowed,
          marital_status_single_divorced: divorced
        })
      }
      if (value === 'Separated') {
        this.$store.commit('personalDetails', { will_for: 'Myself' })
      }
    },
    resetPartner() {
      if (this.partner) {
        this.$store.dispatch('deletePersonFromServer', this.partner.id)
      }
      if (this.partner_ex) {
        this.$store.dispatch('deletePersonFromServer', this.partner_ex.id)
      }
      this.$store.commit('personalDetails', {
        partner_full_name: '',
        partner_gender: '',
        will_for: 'Myself'
      })
      this.$store.commit('checkoutBasket', { will_for: 'Myself' })
    }
  }
}
</script>

<style></style>
